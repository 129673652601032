import React from "react";

// External

// Internal
import Layout from "../components/layout";
import Seo from "../components/SEO";
import MySkillsSection from "../containers/skills-section";

const MySkillsPage = () => {
  return (
    <Layout>
      <Seo
        title="Skills"
        description="Skilled in Frontend Web Development and UI/UX Design"
      />
      <MySkillsSection />
    </Layout>
  );
};

export default MySkillsPage;
